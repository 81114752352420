// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDUk47FJHVX31j2TdukhZPLlCgx9hLERdE",
  authDomain: "samenwerktpeilingen.firebaseapp.com",
  projectId: "samenwerktpeilingen",
  storageBucket: "samenwerktpeilingen.appspot.com",
  messagingSenderId: "797224337401",
  appId: "1:797224337401:web:e68aa266220dd1fb7cc728",
};

// Initialize Firebase
const fb = initializeApp(firebaseConfig);
export default fb;

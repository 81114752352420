import React from "react";
import Peilingen from "../pages/Peilingen";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";

function Homepage() {
	return (
		<div>
			<Container maxWidth="sm">
				<Typography
					variant="h6"
					color="initial"
					sx={{ marginTop: 4, marginBottom: 4 }}
				>
					Samen<strong>Keuzesmaken</strong>Werkt! - Het hoe en waarom van deze
					peiling...
					<br />
				</Typography>
				<Typography variant="body1" color="initial" sx={{ marginBottom: 4 }}>
					Toen we Samen<strong>Werkt</strong> tot stand brachten, was één van de
					belangrijkste elementen die we toen met elkaar bespraken: SamenWerken.
					Dat klinkt heel erg logisch, maar wat betekent dat nu eigenlijk?{" "}
					<br />
					<br /> In ons programma hebben wij duidelijk aangegeven dat wij de
					inwoners van de gemeente Wijk bij Duurstede actief willen betrekken
					bij het bepalen van de politieke agenda van Wijk bij Duurstede. We
					willen steeds proberen daarbij alle meningen en ideeën te horen en te
					wegen. De mening van een tegenstander kan net zo waardevol zijn als
					die van een voorstander. Juist de tegengestelde meningen geven de
					verschillende invalshoeken weer, die van belang zijn om op een goede
					manier tot politieke besluiten te komen. En niet alleen om tot die
					besluiten te komen, maar ook om die besluiten te kunnen verdedigen.
					<br />
					Want als je weet dat je alle invalshoeken hebt bekeken en gewogen, dan
					kun je ook met een gerust hart je besluit verdedigen.
					<br />
					<br />
					Door middel van het uitvoeren van een peiling, proberen wij een eerste
					beeld te krijgen, van wat mensen belangrijk vinden en waarom ze dat
					belangrijk vinden. We willen dus niet alleen het aantal stemmen voor
					of tegen verzamelen, maar ook de argumenten daarbij in beeld krijgen.
					<br />
					<br />
					Op de pagina{" "}
					<Link to="/peilingen">
						Samen<strong>Keuzesmaken</strong>Werkt!
					</Link>{" "}
					vindt u de peilingen die wij willen doen. De verdere uitleg is ook op
					deze pagina opgenomen. Wij hopen dat u de moeite wilt nemen om de
					peilingen in te vullen en uw argumenten daarbij te geven. Op die
					manier kunnen wij een goed beeld krijgen van uw prioriteiten voor de
					begroting van de gemeente Wijk bij Duurstede.
					<br />
					<br />
					Wij danken u heel erg voor uw tijd en moeite. <br />
					Marc Gnodde, Fractievoorzitter
				</Typography>
			</Container>
		</div>
	);
}

export default Homepage;

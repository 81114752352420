import * as React from "react";
import PropTypes from "prop-types";
import Slider, { SliderThumb } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { v4 as uuid } from "uuid";

//add navigationfunction to go back after completing the form
import { useNavigate } from "react-router-dom";

//setup firebase
import { getFirestore, doc, setDoc } from "firebase/firestore";
import fb from "../utilities/FirebaseConfig";

const db = getFirestore(fb);

const initialState = {
  standardValues: [
    {
      id: 1,
      question:
        "Jeugd en onderwijs (Budget: EUR 11 mln.) :: jeugdhulp (omvang: EUR 800.000)",
      information:
        "Jeugdhulp heeft betrekking op de directe hulp die nodig is bij gezondheid, ontwikkeling en gedrag. Daarnaast kan dit ook gaan over opvoedingsvraagstukken. De hulp die geboden gaat kan alle kanten op. In dit budget vallen dan ook onder andere de kosten voor pleegzorg, veilig thuis, maar ook bijvoorbeeld gedragsonderzoeken bij kinderen, alsmede opvoedbegeleiding... Voor meerinformatie over dit onderwerp: https://www.rijksoverheid.nl/onderwerpen/jeugdhulp",
      value: 30,
    },
    {
      id: 2,
      question:
        "Zorg en welzijn (Budget: EUR 18 mln.) :: jeugdgezondheidszorg (omvang: EUR 690.000)",
      information:
        "Dit budget is onder andere bedoeld voor de bijdrage aan de GGD, waar de jeugdgezondheidszorg (o.a. consultatiebureau, schooldokter) onder valt. Daarnaast investeert de gemeente met dit budget onder andere in preventiemaatregelen voor bijvoorbeeld alcohol, maar ook om gezonde leefstijl te bevorderen.",
      value: 30,
    },
    {
      id: 3,
      question:
        "Economie (Budget: EUR 1 mln.) :: Recreatie (omvang: EUR 300.000)",
      information:
        "Dit betreft met name de Gravenbol, Kinderboerderij en algemene kosten recreatie. Het grootste deel van het budget betreft de Gravenbol.",
      value: 30,
    },
    {
      id: 4,
      question:
        "Ruimtelijke ordening, wonen en mobiliteit (Budget: EUR 4 mln.) :: parkeren (omvang: EUR 80.000)",
      information:
        "Het betreffende budget voor parkeren is o.a. bedoeld om knelpunten op te lossen. Dit betreft het verbeteren van parkeervoorzieningen of knelpunten daaromtrent.",
      value: 30,
    },
    {
      id: 5,
      question:
        "Sport, cultuur en erfgoed (Budget: EUR 2 mln.) :: sporthallen/complexen (omvang: EUR 600.000)",
      information:
        "Dit betreft de kosten voor het beheer van alle sportparken in Wijk bij Duurstede. De meeste sportparken zijn al in zelfstandig beheer gebracht. Door middel van zelfwerkzaamheid en inzet van vrijwilligers, zouden de kosten voor het beheer van de sportcomplexen mogelijk naar beneden kunnen.",
      value: 30,
    },
    {
      id: 6,
      question:
        "Duurzaamheid (Budget: EUR 400.000) :: demontagehal (omvang: EUR 49.000)",
      information:
        "Een demontagehal is een duurzame invullling voor hergebruik en dus circulaire economie. Deze activiteit kan pas aanvangen als de nieuwe gemeentewerf wordt opgeleverd. Tot die tijd wordt deze activiteit dus niet uitgevoerd.",
      value: 30,
    },
    {
      id: 7,
      question:
        "Veiligheid en handhaving (Budget: EUR 2,6 mln.):: veiligheidsregio (omvang: EUR 1,5 mln.)",
      information:
        "Dit budget betreft de bijdrage van de gemeente aan de veiligheidsregio. Dit is een zelfstandig orgaan, wat door alle deelnemende gemeenten gefinancierd wordt. Met name lokaal maatwerk (bijvoorbeeld de hoeveelheid betaalde postcommandanten) kosten extra middelen.",
      value: 30,
    },
    {
      id: 8,
      question:
        "Bedrijfsvoering en financiën (Budget: EUR 14 mln):: invoer hondenbelasting (budget: EUR 120.000)",
      information:
        "De hondenbelasting is een belasting die door de gemeente geheven wordt (en werd eerder afgeschaft, maar kan extra middelen voor de gemeente opleveren.). De opbrengst van deze belasting wordt gebruikt voor de algemene middelen van de gemeente. De hondenbelasting is een belasting die door de gemeente geheven wordt. De opbrengst van deze belasting wordt gebruikt voor de algemene middelen van de gemeente. De hondenbelasting is een belasting die door de gemeente geheven wordt. De opbrengst van deze belasting wordt gebruikt voor de algemene middelen van de gemeente.",
      value: 30,
    },
    {
      id: 9,
      question:
        "Beheer en openbare ruimte (Budget: EUR 10 mln.) :: openbaar groen (omvang: 1,2 mln.)",
      information:
        "In deze post vallen de kosten voor het onderhoud van het openbaar groen, zoals parken, plantsoenen en bomen. Daarnaast valt ook het onderhoud van de speeltoestellen onder deze post. Keuzes in het openbaar groen zijn grofgezegd plantsoenen versus bermgras.",
      value: 30,
    },
  ],
};

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={2} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};

const PrettoSlider = styled(Slider)({
  color: "#52af77",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&::before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#52af77",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&::before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

export default function Peilingen() {
  const [message, setMessage] = React.useState("");

  const navigate = useNavigate();
  const reducer = (state, action) => {
    switch (action.type) {
      case "SET_VALUE":
        return {
          ...state,
          standardValues: state.standardValues.map((item) =>
            item.id === action.payload.id
              ? { ...item, value: action.payload.value }
              : item
          ),
        };
      default:
        return state;
    }
  };
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const handleSliderChange = (id, value) => {
    dispatch({ type: "SET_VALUE", payload: { id, value } });
  };

  const docName = uuid();
  const listRef = doc(db, "resultaat_peilingen", docName);

  const handleMessage = (e) => {
    setMessage(e.target.value);
  };

  const sendForm = async () => {
    let createDate = new Date();
    const data = {
      values: state.standardValues,
      message: message,
      insertDate: createDate,
    };

    await setDoc(listRef, data);
    navigate("/");
  };

  return (
    <>
      <Container sx={{ marginTop: 4, maxWidth: "640px" }}>
        <Typography variant="h4" gutterBottom>
          Peilingen
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 4 }}>
          Onderstaande vind je een selectie van budgetten uit de begroting 2024
          van de gemeente Wijk bij Duurstede. Uiteraard is dit een beperkte
          selectie van de totale begroting. We hebben geprobeerd uit te lichten
          waar keuzes gemaakt moeten worden. Geef met de sliders aan hoe
          belangrijk jij het betreffende item vindt. Natuurlijk kun je niet
          alles even belangrijk maken, dus probeer een goede afweging te maken.
          Eigenlijk hopen we ook op andere goede ideeën om de begroting van Wijk
          bij Duurstede sluitend te krijgen. Daarom kun je onderstaande zelf nog
          een eigen voorstel toevoegen. Dit kan een bezuiniging zijn, maar ook
          een extra investering. We zijn benieuwd naar jouw ideeën!
          <br />
          <br />
          Vergeet aan het einde niet op de knop 'Verstuur' te drukken.
          <br />
          <br />
          We hebben er voor gekozen de peiling anoniem te houden, maar: wil je
          met ons in gesprek, klik dan na het invullen in de menubar op 'Meedoen
          met SamenWerkt' en vul het contactformulier in.
          <br />
          <br />
          Voor meer info, klik op de vraagtekens. (telefoon: 2 seconden drukken,
          telefoon overdwars houden of laptop werkt beter)
        </Typography>
        {state.standardValues.map((item) => (
          <Box sx={{ width: 700 }} id={item.id}>
            <Typography gutterBottom>
              {item.question}
              <Tooltip title={item.information} leaveTouchDelay="6000">
                <HelpCenterIcon />
              </Tooltip>
            </Typography>

            <PrettoSlider
              valueLabelDisplay="auto"
              aria-label="pretto slider"
              value={item.value}
              onChange={(e) => handleSliderChange(item.id, e.target.value)}
            />
            <Box sx={{ m: 3 }} />
          </Box>
        ))}
        <TextField
          id="personal-message"
          sx={{ width: "400px", marginRight: 4 }}
          label="Heb je zelf suggesties? Laat het ons weten!"
          multiline
          rows={8}
          onChange={handleMessage}
          variant="filled"
        />
        <Button variant="contained" onClick={sendForm}>
          Mijn peiling versturen
        </Button>
      </Container>
    </>
  );
}

import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import Button from "@mui/material/Button";
import { v4 as uuid } from "uuid";
//add navigationfunction to go back after completing the form
import { useNavigate } from "react-router-dom";

//setup firebase
import { getFirestore, doc, setDoc } from "firebase/firestore";
import fb from "../utilities/FirebaseConfig";

const db = getFirestore(fb);

function Meedoen() {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");

  const navigate = useNavigate();
  const docName = uuid();
  const listRef = doc(db, "doe_mee", docName);
  const sendPerson = async () => {
    try {
      await setDoc(listRef, {
        name: name,
        email: email,
        phone: phone,
      });
      navigate("/");
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };
  return (
    <div>
      <Container maxWidth="sm" sx={{ marginTop: 10 }}>
        <Box>
          <Typography variant="h4">Meedoen of meedenken?</Typography>
          <br />
          <Typography variant="body1"></Typography>
          <br />

          <PersonPinIcon sx={{ fontSize: 40 }} />
          <TextField
            id="outlined-basic"
            label="Naam"
            variant="outlined"
            sx={{ margin: 2, width: "300px" }}
            onChange={(e) => setName(e.target.value)}
          />
          <br />
          <ForwardToInboxIcon sx={{ fontSize: 40 }} />
          <TextField
            id="outlined-basic"
            label="Mailadres"
            variant="outlined"
            sx={{ margin: 2, width: "300px" }}
            onChange={(e) => setEmail(e.target.value)}
          />
          <br />
          <PermPhoneMsgIcon sx={{ fontSize: 40 }} />
          <TextField
            id="outlined-basic"
            label="Telefoonummer"
            variant="outlined"
            sx={{ margin: 2, width: "300px" }}
            onChange={(e) => setPhone(e.target.value)}
          />
          <br />
        </Box>
        <br />
        <Button variant="contained" onClick={sendPerson}>
          Ik doe of denk mee, verzend mijn gegevens nu!
        </Button>
      </Container>
    </div>
  );
}

export default Meedoen;

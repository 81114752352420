import React from "react";
import Topbar from "./components/Topbar";
import Footer from "./components/Footer";
import Homepage from "./pages/Homepage";
import { Routes, Route } from "react-router-dom";
import Peilingen from "./pages/Peilingen";
import Meedoen from "./pages/Meedoen";

function App() {
  return (
    <div>
      <Topbar />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/peilingen" element={<Peilingen />} />
        <Route path="/meedoen" element={<Meedoen />} />
      </Routes>
    </div>
  );
}

export default App;
